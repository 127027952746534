@import "./variables.scss";
@import "./custom.scss";

.help-text-modal {
    .btn.btn-primary{
        background-color: #dd2729;
        border-color: #dd2729;
    }
    .btn.btn-secondary {
        background-color: #545b62;
        border-color: #4e555b;
    }
}

.modal~.modal {
    z-index: 1062;
}
.modal-backdrop~.modal-backdrop {
    z-index: 1061;
}
.modal~.modal~.modal {
    z-index: 1063;
}
.modal-backdrop~.modal-backdrop~.modal-backdrop {
    z-index: 1062;
}

.bg-white {
    background-color: rgb(255, 255, 255) !important;
}

.product-image-placeholder {
    .slider-container {
        margin: 0 -5px;

        .skeleton-placeholder {
            height: 75px;

            @media screen and (min-width: 576px) {
                height: 150px;
            }
        }
    }
}

.product-details-view {
    min-height: 574px;
}

.product-image-view {
    position: relative;
    width: 100%;
    height: 571px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: "\f00e";
        position: absolute;
        bottom: 0;
        right: 15px;
        color: #e4292b;
        font-size: 20px;
    }

    &:hover {
        &::after{
            color: #555;
        }
    }

    @include media-min-md {
        .image-magnifying-output {
            margin-left: 30px;
            margin-top: 0 !important;
        }
    }

    .image-magnifier-info i {
        color: #e5292b;
    }

    img {
        max-width: 100%;
        max-height: 571px;
    }

    @include media-max-lg {
        height: 400px;
        img {
            max-height: 400px;
        }
    }

    .facade {
        height: 100%;

        .facade-title {
            height: 100%;
            width: 100%;
        }
    }

    .product-image-preview {
        position: absolute;
        top: 5px;
        right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        color: #fcfcfc;
        background-color: rgba(0,0,0,.4);
        border: 0;
        border-radius: 50%;
        padding: 0;
        outline: 0;
        opacity: 0;
        transition: 300ms opacity ease-in-out;
    }

    &:hover {
        :not(.file-parent) {
            .product-image-preview {
                opacity: 1;
            }
        }
    }
}

.document-manager .document-folder-content table.document-files tbody > tr > td {
   width: 200px !important;
   min-width: 200px;
   max-width: 200px !important;
   overflow-wrap: anywhere;
}

.table-curved {
    border-collapse: separate;
}
.table-curved {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}
.table-curved td, .table-curved th {
    border-left: 1px solid rgba(0,0,0,.125);
    border-top: 1px solid rgba(0,0,0,.125);
}
.table-curved tr > *:first-child {
    border-left: 0px;
}
.table-curved tr:first-child > * {
    border-top: 0px;
}