@import './variables.scss';

@mixin label-text {
    color: $secondary-color;
    font-size: 17px;
    font-weight: 300;
}

body {

    .modal-preview .modal-dialog .modal-content {
        .container-fluid.is-image {
            padding: 0 !important;
            padding: 65px 30px 30px !important;
    
            .preview-item {
                color: #555 !important;
                background-color: #fff;
    
                .loader-spinner .loader-content .spinner-border {
                    border-color: #555;
                    border-left-color: transparent;
                }
            }
        }

        .preview-item-header {
            padding: 15px 30px;
        }
    } 

    .modal .modal-content .modal-header .close {

        &:focus {
            box-shadow: none;
            outline: none;
        }

        & ~ .close {
            margin-left: 0;
        }

        &.other-action {
            background: none;
            line-height: 1;
            font-size: 13px !important;
            margin-top: -2px;
            margin-bottom: -2px;
            margin: -0.6rem -1rem -0.5rem 0;
        }
    }
    // all overides inside here
    .sticky-message {
        position: fixed;
        bottom: 100px;
        z-index: 100000;
        .alert.alert-danger {
            color: #721c24;
            background-color: #f8d7da;
            border-color: #f5c6cb;
            animation: 0.7s pulse alternate infinite;
        }

        @keyframes pulse {
            from {
                box-shadow: 0px 0px 0px 0px rgba(255, 0, 0, 0.5);
            }
            to {
                box-shadow: 0px 0px 0px 10px rgba(250, 32, 32, 0.5);
            }
        }
    } 
    
    &:not(.native-mobile) {
        #retoast-top, #retoast-top-left, #retoast-top-right {
            top: 115px;
            flex-direction: column;
        }

        .retoast {
            font-weight: 600;
            background-color: #fcfcfc;
            border: 1px solid #adabac;
            border-radius: 0;
            border-bottom: 3px solid #e6282c;
            &-content {
                &-close {
                    color: #525252;
                }
            }
            
            &.retoast-warning {
                color: #fa0;
            }
    
            &.retoast-success {
                color: #0ab38d;
            }
    
            &.retoast-danger {
                color: #ff4c60;
            }
    
            &.retoast-info {
                color: #6c7ee5;
            }

            .retoast-container {
                border-bottom: 3px solid #adabac;
                height: 102%;

                .toaster-message {
                    font-size: 14px;
                    color: #525252 !important;
                }
            }
        }
    }

    .password-field-show {
        color: #888;
    }

    .table-lowpad {
        td {
            padding: 0.5em 0.75rem;
        }
    }

    .btn.btn-halspan-small {
        color: #fff;
        padding: 0.5rem 2rem;
        font-size: 13px;
        border-radius: 50px;
        height: auto;
        width: auto;
        line-height: 16px;
        background-color: #e6282b;
        border-color: #dc3545;

        &.btn-halspan-secondary {
            background-color: #545b62;
            border-color: #4e555b;
            &.active, &:hover {
                background-color: #B5B5B5;
                border-color: #b3b3b3;
            }
        }

    }

    .hidden {
        visibility: hidden !important;
    }

    .halspan-btn-primary, .halspan-btn-secondary {
        display: flex;
        align-items: center;
        font-weight: 700;
        color: #fff !important;
        font-size: 10pt;
        border-radius: 30px;
        min-width: 135px;
        min-height: 40px;
        border: 1px solid #E4282B !important;
        background: #E4282B !important;
    }

    .halspan-btn-secondary {
        background-color: #545b62 !important;
        border-color: #4e555b !important;
    }

    .image-td {
        max-height: 150px;
    }

    .modal {
        .nav-tabs .nav-link {
            color: #555;
        }
    }
    
    .align-checkbox {
        margin-top: 23px;
        margin-bottom: 35px;
    }
    .custom-control-input:checked~.custom-control-label::before {
        color: #fff;
        border-color: #707070;
        background-color: #e6282b;
    }

    .custom-control-label{
        line-height: 1.5;
        &::before {
            position: absolute;
            background-color: #fff;
            border: #707070 solid 1px;
            border-radius: 0 !important;
        }
    }

    @include media-max-sm {
        .nav-search-icon {
            width: 35px;
            height: 35px;
            font-size: 15px;
        }
        a.nav-search-button {
            padding: 0;
            width: 30px;
            height: 30px;
        }
        .ribbon-text.wc-door-config-title {
            padding-top: 0;
        }

        .grey-ribbon {
            height: auto;
        }
        
    }

    .wc-door-core-product-multiple-col {
        border: 0;
    }

    .wc-products-home-side-menu li .btn {
        display: flex;
        align-items: center;
        padding: 6px 15px;

        i {
            top: 0;
            margin-right: 5px;
        }
    }

    .wc-request-quote {
        td {
            img {
                max-height: 125px;
            }
            
            &[data-facade="true"] {
                height: 125px;
                width: 125px;
            }
        }
    }

    .card-product {
        .card-product-img {
            display: flex;
            justify-content: center;
            img {
                height: 175px;
                max-width: 175px;
            }
        }
    }

    .btn-round {
        border-radius: 30px;
    }

    .alert.alert-danger {
        background-color: $primary-color;
        color: #fff;
    }

    .clickable {
        cursor: pointer;
    }
    
    .white-space-pre {
        white-space: pre;
    }

    .btn-halspan.bg-transparent {
        outline: none;
        box-shadow: none !important;
        border-color: transparent !important;
        color: $primary-color !important;
        background-color: transparent !important;
    }

    .halspan-auth-right {
        .img-fluid {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    @include media-min-lg {
        .halspan-auth-left {
            padding-left: 7rem !important;
            padding-right: 7rem !important;
        }
    }

    .form-number-split-action {
        > input {
            padding: 3px .75rem !important;
            width: 90px;
        }
        > .form-number-action {
            padding: 3px 8px;
        }
    }

    .table-no-data {
        padding: 6rem;
    }

    .load-forge-content {
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #e4282b;
    }

    .alert-dismissible {
        padding-right: 30px;
    }
    
    .alert-close {
        font-size: 24px;
        line-height: 1;
        padding: 0 !important;
        margin-left: 5px;
        color: inherit !important;
        background-color: transparent !important;
        border: 0 !important;
        outline: 0 !important;
        position: absolute;
        top: 11.5px;
        right: 8px;
    }
    
    .wc-box-shadow.select-toggle {
        padding: 5px 10px 4px 20px;
        border: 0 !important;
    }
    
    .invalid-message {
        display: block;
        margin: -7px 0 10px;
        color: #f56264;
        font-size: 13px;
    }

    .form-group {
        
        label {
            @include label-text;

            .required-asterisk {
                color: #dc3545;
            }
        }

        .form-control, .form-number > input {
            height: auto;
            border-radius: 0 !important;
            padding: .475rem .75rem;
            // background-color: transparent;
            color: $secondary-color;
        }

        .select-dropdown > {
            .select-toggle {
                color: $secondary-color;
                border-radius: 0 !important;
                // background-color: transparent;
                border-color: #ced4da !important;

                &:focus + .select-toggle > .select-placeholder {
                    opacity: 0;
                }

                &::placeholder, > .select-placeholder {
                    color: #6c757d !important;
                }
            }
        }
    }


    .form-check {
        margin-bottom: 1.5rem;

        .form-check-input {
            width: 15px;
            height: 15px;

            &.is-invalid {
                border-color: #dc3545;
            }
        }

        .form-check-label {
            @include label-text;
        }
    }

    .select-dropdown {
        height: 42px;
        
        > .select-toggle {
            display: flex !important;
            justify-content: space-between;
            // background-color: transparent !important;
            padding: .475rem .75rem !important;
            border-radius: 0 !important;
            color: $secondary-color !important;
            min-height: 40px !important;
            height: 42px !important;
            border: 1px solid #707070 !important;

            .fa-angle-right {
                margin: -20px 0;
                font-size: 35px;
                color: rgba($color: #000000, $alpha: 0.5)
            }

            .select-placeholder {
                color: #cccccc !important;
            }
        }

        &.disabled {
            input.select-toggle {
                opacity: 0;
            }
        }

        > .select-options-container {
            > .select-option {
                color: #6c757d;
                
                &:hover, &.selected {
                    background-color: rgba(230, 39, 43, 0.078) !important;
                    color: #af5254;
                }
            }
        }
        
        &.is-invalid {

            > .select-toggle {
                border-color: #dc3545 !important;
            }
        }
    }

    .auto-complete-select {
        .css-yk16xz-control {
            padding: 0rem 0.45rem !important;
            // border-color: #707070 !important;
            border-radius: 0 !important;
            min-height: 42px;
            // border-color:  #6c757d;
        }

        &.is-invalid > .css-yk16xz-control {
            border-color: #dd3444 !important;
        }

        .css-1fhf3k1-control {
            padding: 0rem 0.45rem !important;
            border-radius: 0 !important;
            border-color: #6c757d;
            min-height: 42px;
            box-shadow: 0 0 0 0.2rem rgba(112, 112, 112, 0.2)
        }

        .css-1pahdxg-control {
            padding: 0rem 0.45rem !important;
            border-radius: 0 !important;
            border-color: #6c757d;
            min-height: 42px;
            box-shadow: 0 0 0 0.2rem rgba(112, 112, 112, 0.2);

            &:hover {
                border-color: #707070;
                box-shadow: 0 0 0 0.2rem rgba(112, 112, 112, 0.2)
            }
        }

        .css-1wa3eu0-placeholder {
            color: #6c757d;
        }

        .css-26l3qy-menu {
            top: 103%;
            margin: 0;
            border-radius: 1px;
            padding: 5px 0px;

            .css-4ljt47-MenuList {
                padding: 0;

                .css-1n7v3ny-option {
                    padding: 9px 10px;
                    background-color: rgba(230, 39, 43, 0.078) !important;
                    color: #af5254;
                }
                .css-yt9ioa-option {
                    padding: 9px 10px;
                }
            }
        }
    }

    .wc-box-shadow {
        &.is-invalid {
            box-shadow: 0 0 10px 5px rgba(255, 39, 39, 0.271) !important;
        }
    }
    
    .input-box.no-shadow {
        &.is-invalid {
            .custom {
                box-shadow: none !important;
                border: 1px solid rgba($color: $primary-color, $alpha: 0.57) !important;
            }
        }

        > label {
            .custom {
                background: #fff;
                box-shadow: none !important;
                border: 1px solid #707070 !important;
            }
        }

        &:not(.radio) {
            > label {
                .custom:before {
                    border-color: #707070 !important;
                }
            }
        }

        &.radio {
            > label {
                .custom:before {
                    background-color: #707070 !important;
                }
            }
        }
    }


    .form-shadow {
        > label {
            font-weight: 900;
        }

        .form-control, .form-number {
            background-color: #fff;
            border: 0 !important;
            box-shadow: 0 0 10px rgba(82, 82, 82, 0.468);
            border-radius: 0;
            
            &:focus {
                box-shadow: 0 0 10px rgba(0, 123, 255, 0.582);
            }
        
            &.is-invalid, &.is-invalid:focus {
                box-shadow: 0 0 10px rgba(220, 53, 70, 0.575);
            }
        
            &.is-valid, &.is-valid:focus  {
                box-shadow: 0 0 10px rgba(40, 167, 70, 0.543);
            }
        }

        .form-number > input {
            border: 0;
        }

        .select-dropdown {

            > input {
                + .select-toggle {
                    background-color: #fff !important;
                    border: 0 !important;
                    box-shadow: 0 0 10px rgba(82, 82, 82, 0.468);
                }
                
                &:focus + .select-toggle {
                    box-shadow: 0 0 10px rgba(0, 123, 255, 0.582) !important;
                }
            }

            &.is-invalid > input {
                + .select-toggle, &:focus  + .select-toggle {
                    box-shadow: 0 0 10px rgba(220, 53, 70, 0.575) !important;
                }
            }

            &.is-valid > input {
                + .select-toggle, &:focus  + .select-toggle {
                    box-shadow: 0 0 10px rgba(40, 167, 70, 0.543) !important;
                }
            }
        }

        .auto-complete-select {
            .css-yk16xz-control {
                background-color: #fff !important;
                border: 0 !important;
                box-shadow: 0 0 10px rgba(82, 82, 82, 0.468);
            }
        
            &.is-invalid > .css-yk16xz-control {
                box-shadow: 0 0 10px rgba(220, 53, 70, 0.575) !important;
            }
        
            .css-1fhf3k1-control {
                background-color: #fff !important;
                border: 0 !important;
                box-shadow: 0 0 10px rgba(82, 82, 82, 0.468); 
            }

            .css-1pahdxg-control {
                background-color: #fff !important;
                border: 0 !important;
                box-shadow: 0 0 10px rgba(0, 123, 255, 0.582) !important;
            }
        }
    }

    .alert-dismissible {
        padding-right: 30px;
    }

    .alert-close {
        font-size: 24px;
        line-height: 1;
        padding: 0 !important;
        margin-left: 5px;
        color: inherit !important;
        background-color: transparent !important;
        border: 0 !important;
        outline: 0 !important;
        position: absolute;
        top: 11.5px;
        right: 8px;
    }

    [aria-expanded] > .fa-angle-right,
    [aria-hidden] > .fa-angle-right {
        transition: transform 150ms ease-in-out;
    }

    [aria-expanded="true"] > .fa-angle-right,
    [aria-hidden="false"] > .fa-angle-right {
        transform: rotate(90deg);
    }

    [aria-expanded="false"] > .fa-angle-right,
    [aria-hidden="true"] > .fa-angle-right {
        transform: rotate(0deg);
    }

    .text-normal {
        @include label-text;

        font-size: 17px;
    }

    .text-bold {
        color: $secondary-color;
        font-size: 17px;
        font-weight: 600;
    }

    .text-bold-title {
        color: $secondary-color;
        font-size: 21px;
        font-weight: 600;
    }

    .text-small {
        color: $secondary-color;
        font-size: 13px;
    }

    @for $i from 1 to 9  {
        .text-b-#{$i} {
            font-weight: 100 * $i;
        }
    }

    .btn.btn-halspan-prima {
        border-radius: 50px !important;
        background-color: $primary-color;
        border-color: lighten($primary-color, 0.6) !important;
        font-weight: 600;

        &:active {
            border-color: lighten($primary-color, 0.6) !important;
            background-color: $primary-color !important;
            box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5) !important;
        }

        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
        }
    }

    .modal {
        &.halspan-modal {
            .modal-content {
                background-color: #f3f3f3;
            }

            .modal-header {
                background-color: $secondary-color;
                color: #fff;

                .modal-title {
                    font-weight: 700;
                }

                .fa {
                    color: #fff;
                }

                .close {
                    color: #fff;
                }
            }

            .modal-body {
                padding: 1rem 1.5rem;

                .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
                    color: #495057;
                    background-color: #f3f3f3;
                    border-color: #dee2e6 #dee2e6 #f3f3f3;
                }

                .form-group {
                    margin-bottom: 1rem;

                    label {
                        font-size: 15px;
                        margin-bottom: 0.3rem;
                    }
                }
            }
        }

        .modal-footer {
            padding: 1rem 1.5rem;

            .alert {
                width: 100%;
            }

            .btn {
                border-radius: 50px !important;
                background-color: $primary-color;
                border-color: lighten($primary-color, 0.6) !important;
                font-weight: 600;

                &:active {
                    border-color: lighten($primary-color, 0.6) !important;
                    background-color: $primary-color;
                    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5) !important;
                }

                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
                }
            }
        }

        // &.modal-adjust {
        //     @media (min-width: 992px) {
        //         :not(.modal-lg) .modal-content {
        //             margin-left: 27%;
        //         }
            
        //         .modal-sm > .modal-content {
        //             margin-left: 47%;
        //         }
        //     }
            
        //     @media (min-width: 1100px) {
            
        //         .modal-lg > .modal-content {
        //             margin-left: 17%;
        //         }
                
        //     }
        // }
        
    }

    .navbar-collapse {
        overflow: visible;
    }

    .halspan-navbar-ember {
        .col-md-12.wc-header-blank {
            min-height: 65px;
            @include media-min-sm {
                min-height: 100px;
            }
        }
    }

    .halspan-card {
        position: relative;
        color: $secondary-color;
        background-color: #ececec;
        padding: 25px 30px;
        box-shadow: 0 0 20px 0px rgba(82, 82, 82, 0.45);
        margin-bottom: 30px;

        &.halspan-card-secondary {
            background-color: #fff;
            box-shadow: 0 0 20px 0px rgba(82, 82, 82, 0.25);
        }

        .halspan-card-title {
            font-weight: 600;
            font-size: 23px;

            @include media-min-sm {
                font-size: 28px;
                margin-bottom: 20px;
            }
        }

        // .auto-complete-select {
        
        //     .css-1wa3eu0-placeholder {
        //         font-weight: 700;
        //     }
        // }
    }

    .halspan-card, .modal-body {
        legend {
            width: auto;
            font-size: 14px;
            font-weight: 600;
            padding: 0 10px;
        }
        
        fieldset {
            position: relative;
            padding: 5px 20px;
            border: 1px solid lighten($secondary-color, 40);

            .halspan-verified {
                position: absolute;
                top: -58px;
                right: -38px;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                overflow: hidden;
            }
        }

        .form-group {
            .form-label {
                font-size: 16px;
            }

            .input-box.inline > label,
            .form-label,
            .custom-control-label,
            .postcode-label {
                font-weight: 600 !important;
            }
        }
        .form-control, .select-toggle, .form-number > input {
            // font-weight: 700;
            &:not(textarea) {
                height: 42px;
            }
            padding: 10px 15px !important;
        
            &::placeholder {
                font-weight: 400;
                color: #ccc;
            }
        }
        
        .form-control, .select-dropdown > .select-toggle, .form-number > input {
            // border-color: #707070 !important;
            background-color: #fff !important;
            border-radius: 0;
            &:not(textarea) {
                height: 42px;
            }

            &.is-invalid {
                border-color: #dc3545 !important;
            }

            &:focus {
                color: #495057;
                background-color: #fff;
                border-color: rgb(112, 112, 112) !important;
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgba(112, 112, 112, 0.2)
            }
        }

        .select-dropdown.is-invalid > .select-toggle{ 
            border-color: #dc3545 !important;
        }

        .select-dropdown > .select-toggle {
            min-height: 42px !important;
        }

        .form-shadow {
            .select-dropdown > .select-toggle {
                min-height: 42px !important;
            }
        }
        
        .select-dropdown.disabled > .select-toggle, :disabled:not(.btn), .disabled:not(.page-item) {
            background-color: #ebebeb !important;
        }
        
        .input-group-prepend {
            .select-toggle {
                height: 100%;
            }
        }

        .input-group-text {
            border-radius: 0px;
        }
    }

    .halspan-search {
        width: auto;

        .input-group-text {
            border: 2px solid $secondary-color;
            border-radius: 50px 0 0 50px;
            background-color: #fff;
            border-right: 0;
        }

        .form-control {
            padding-left: 0 !important;
            height: 42px;
            border: 2px solid $secondary-color;
            border-radius: 0 50px 50px 0;
            border-left: 0;
            box-shadow: none !important;

            @include media-min-sm {
                max-width: 260px;
            }
        }
    }

    .halspan-table {
        tbody td, thead th {
            // text-align: center;
            border: 2px solid #e1e1e1;
            background-color: #fff;
            padding: 8px;
            color: $secondary-color;
            vertical-align: center;
            font-size: 13px;
            white-space: pre;

            @include media-min-sm {
                font-size: 15px;
            }
        }
        thead th {
            background-color: #8d8d8d;
            color: #fff;
        }

        tbody .haslpan-grid-row {
            cursor: pointer;
        }

        .limit-table-data {
            max-width: 100px;
            white-space: pre-line; 
            word-break: break-word;
        }

        .btn-action {
            color: #8d8d8d;
            font-size: 16pt;
            color: $secondary-color;
            background-color: transparent;
            border: 0;
            appearance: none;
            line-height: 1;
            outline: 0;
            margin: -10px 0;

            &:hover, &.active {
                color: $primary-color;
            }
        }
    }

    .action-btns {
        .btn {
            font-size: 16pt;
            color: $secondary-color;
            background-color: transparent;
            border: 0;
            appearance: none;
            line-height: 1;
            outline: 0;
            margin: -10px 0;

            &:hover, &.active {
                color: $primary-color;
            }
        }
    }

    .halspan-navbar {
        .basket-count {
            padding: 1px 6px;
            background: #e5282b;
            font-size: 11px;
            border-radius: 50px;
            position: absolute;
            top: -8px;
            right: -5px;
            font-weight: 600;
        }
        .dropdown {
            .dropdown-toggle {
                &::after {
                    content: none;
                }
            }
            .dropdown-menu {
                background-color: $secondary-color;
                top: 29px !important;
                border: 0;
                box-shadow: 0 10px 10px rgba($color: #000000, $alpha: 0.2);

                .dropdown-item {
                    color: #ffffff;
                    font-weight: 600;
                    padding: 10px 15px;

                    &:hover {
                        background-color: $primary-color;
                    }
                    &:active, &.active {
                        color: #fff;
                        background-color: $primary-color;
                    }

                    &:disabled, &.disabled {
                        color: lighten($secondary-color, $amount: 20);
                    }
                }
            }
        }
    }

    .native-mobile {
        background: transparent;
        
        .halspan-card {
            background-color: transparent;
            box-shadow: none;
            padding: 15px 10px;
        }

        .btn-halspan {
            border-radius: 2px !important;
        }

        .halspan-content {
            background-color: transparent;
        }

        .form-group {
            label {
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 5px;
            }

            .form-control, .select-toggle {
                font-size: 14px;
                padding: 10px 15px !important;
                border-radius: 1px;
                font-weight: 400;
            }

            .select-toggle {
                min-height: 40px !important;
            }
        }

        .no-margin-native {
            margin: 0 !important;
        }

        .halspan-navbar,
        .halspan-navbar-ember,
        .halspan-banner,
        .halspan-footer,
        .halspan-side-nav,
        .loader-spinner,
        #org-changer {
            display: none !important;
        }

        #retoast-top, #retoast-top-left, #retoast-top-right {
            top: 30px;
            flex-direction: column;
        }
    }

    .box-shadow-none {
        box-shadow: none !important;
    }

    .breadcrumb {
        background: none;
        padding: 0;

        .breadcrumb-item {
            a {
                color: #525252;

                &:hover {
                    color: #007bff;
                }
            }
        }
    }

    .freeze-table-column {
        table {
            th:nth-child(1),
            td:nth-child(1) {
                position: sticky;
                left: 0;
            }
        }
    }

    .link {
        color: #525252 !important;
        text-decoration: underline !important;
        cursor: pointer !important;
        
        &:hover {
            color: #e4282b !important;
            text-decoration: unset !important;
        }
    }

    .link-red {
        color: #e4282b !important;
        text-decoration: underline !important;
        cursor: pointer !important;

        &:hover {
            color: #525252 !important;
            text-decoration: unset !important;
        }
    }
}