/*edited*/
html, body {
    height: 100%;
}

body {
    padding: 0;
    margin: 0;
    background: #ececec;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Helvetica, Arial, sans-serif;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.halspan-auth {
    background-color: #f4f4f4;
}

.halspan-content {
    position: relative;
    background-color: #fff;
    flex: 1 0 auto;
}

.halspan-auth-right {
    display: none;
    padding-left: 0;
    padding-right: 0;
    /* background-image: url(../images/halspan-bg-auth-right.png); */
    background-repeat: no-repeat;
    background-size: cover;
    height: 960px;
    max-height: 960px;
    position: relative;
}

@media (min-width: 768px) {
    .halspan-auth-right {
        display: block;
    }
}

.halspan-auth-left {
    padding-top: 5rem;
}

.halspan-navbar .navbar {
    background-color: #ffffff;
    background-image: url(../images/header-bg-shape.jpg);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
    height: auto;
    /*    
    position: fixed;*/
}

.halspan-navbar .navbar {
    padding: 0;
}

.halspan-logo {
    padding: 8px 0;
}

    .halspan-logo img {
        width: 200px;
        max-width: 100%;
        height: auto;
        transition-duration: 0.3s;
    }

.nav-contact-button {
    display: block;
    line-height: 1;
    background-color: #E4282B;
    font-size: 15px;
    padding: 12px 24px;
    border-radius: 3px;
    font-family: Helvetica, Arial, sans-serif;
    border-radius: 3px;
    color: #fff;
    fill: #fff;
    text-align: center;
    transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    border-radius: 50px;
    font-weight: bold;
    text-decoration: none;
    margin: 30px 0;
}

    .nav-contact-button:hover {
        background-color: #000000;
        text-decoration: none;
        color: #ffffff;
    }

a.nav-search-button {
    padding: 0;
    width: 40px;
    height: 40px;
    display: block;
    margin: 30px 0;
}

.nav-search-icon {
    width: 40px;
    height: 40px;
    font-size: 20px;
    background-color: #525252;
    border-radius: 100px;
    color: #ffffff !important;
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;
    text-align: center;
    transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
}

    .nav-search-icon a {
        color: #ffffff;
    }

    .nav-search-icon:hover {
        background-color: #E4282B;
    }

    .nav-search-icon i {
        padding-top: 10px;
    }
    .wc-header-blank {
        min-height: 65px;
    }
    @media (min-width: 576px) {
        .wc-header-blank {
            min-height: 100px;
        }
    }
footer * {
    font-family: Helvetica, Arial, sans-serif;
    color: #525252;
}

.footer-logos {
    background: #ffffff;
    padding: 25px 0
}

ul.logos {
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

    ul.logos li {
        display: inline-block;
        text-align: center;
        padding: 10px;
        min-width: 200px;
    }

        ul.logos li img {
            vertical-align: middle
        }

.footer-main {
    background-color: var( --e-global-color-1f1fcf6 );
    background-image: url(../images/footer-shape.png);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
}

.footer-content {
    border-top: 5px solid #E4282B;
    padding: 25px 0 0 0;
}

.footer-logo {
    padding: 10px 0;
}

.pad25 {
    padding: 25px 0;
}

footer a {
    color: #ff0000;
    text-decoration: none;
}

    footer a:hover {
        color: #000000;
        text-decoration: none;
    }

a.social-icon {
    border-radius: 50%;
    background: #525252;
    width: 50px;
    height: 50px;
    color: #ffffff;
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;
    text-align: center;
    margin-right: 10px;
}

    a.social-icon i {
        padding-top: 12px;
        color: #ffffff;
        font-size: 25px;
    }

ul.other-links {
    margin: 0;
    padding: 0;
    list-style: none;
}

    ul.other-links li {
        font-weight: bold;
    }

        ul.other-links li a {
            color: #525252;
            text-decoration: none;
        }

            ul.other-links li a:hover {
                color: #ff0000;
                text-decoration: none;
            }

                ul.other-links li a:hover span {
                    color: #ff0000;
                }

        ul.other-links li i {
            width: 1.25em;
            color: #ff0000;
        }

.footer-copyright {
    background-color: #525252BF;
    color: #fff;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 25px 0px;
    font-size: 14px;
}

    .footer-copyright p {
        color: #ffffff;
    }

.img-centred {
    margin: inherit auto !important;
}

.txt-center {
    text-align: center;
}

.d-xs-none {
    display: block !important;
}

.red {
    color: #f90000;
}

button.form-control.rounded {
    border: 1px solid #E4282B;
    background: #E4282B;
    border-radius: 23px !important;
    font-family: Helvetica, Arial, sans-serif !important;
    font-size: 16px;
    line-height: 11px !important;
    padding: 15px 30px;
    height: 46px;
    width: auto;
    margin: 0 auto !important;
}

    button.form-control.rounded.learn-more {
        margin: 0 !important;
        padding: 0 14px 0 20px;
    }

.line-height-3em {
    line-height: 2em;
}

.mobile-bg {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 360px;
}

.halspan-verified-mobile {
    position: absolute;
    z-index: 2;
    left: 60px;
    top: -220px;
}
/******************************************************
SEARCH
******************************************************/
#search-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #525252D4;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    transform: translate(0px, 0px) scale(0, 0);
    -moz-transform: translate(0px, 0px) scale(0, 0);
    -o-transform: translate(0px, 0px) scale(0, 0);
    -ms-transform: translate(0px, 0px) scale(0, 0);
    transform: translate(0px, 0px) scale(0, 0);
    opacity: 0;
    z-index: 1031;
}

    #search-overlay.open {
        transform: translate(0px, 0px) scale(1, 1);
        -moz-transform: translate(0px, 0px) scale(1, 1);
        -o-transform: translate(0px, 0px) scale(1, 1);
        -ms-transform: translate(0px, 0px) scale(1, 1);
        transform: translate(0px, 0px) scale(1, 1);
        opacity: 1;
    }

    #search-overlay form input[type=text] {
        font-size: 50px;
        text-align: center;
        border: solid #fff !important;
        line-height: 1.5;
        color: #fff;
        position: absolute;
        top: 206px;
        left: 50%;
        width: 70%;
        height: 110px;
        transform: translate(-50%, -50%);
        color: #525252;
        font-weight: 500;
        text-align: center;
        border: 0px;
        margin: 0px auto;
        margin-top: -51px;
        padding-left: 30px;
        padding-right: 30px;
        outline: none;
        border: 5px solid #E4282B !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        border-radius: 0px;
        font-family: Helvetica, Arial, sans-serif !important;
    }

    #search-overlay form input[type=text]::placeholder {
        color: #52525285
    }
    
/*******************************************************/


img.img-fluid.float-right.position-absolute {
    top: 36px;
}

a {
    color: #E4282B;
    text-decoration: none;
}

a:hover {
    color: #E4282B;
    text-decoration: none;
}

.nav-link {
    color: #555;
}

@media (max-width: 575px) {
    .d-xs-none {
        display: none !important;
    }

    .halspan-navbar .navbar {
        height: auto;
    }
    .nav-menu-mobile {
        top: 65px !important;
    }

    a.nav-search-button {
        padding: 0;
        width: 40px;
        height: 40px;
        display: block;
        margin: 10px 0;
    }

    .nav-menu-mobile-toggle, 
    .nav-menu-mobile-toggle-expanded {
        display: block;
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 10px 0 !important;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .d-xs-none {
        display: none !important;
    }

    .halspan-logo {
        padding: 8px 0;
    }

        .halspan-logo img {
            width: 200px;
            max-width: 100%;
            height: auto;
            transition-duration: 0.3s;
        }

    .mobile-bg {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-top: 420px;
    }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {

    .d-xs-none {
        display: block !important;
    }

    .halspan-navbar .navbar {
        height: 100px;
        padding: 0 15px;
    }

    .halspan-logo {
        padding: 10px 0 10px 10px;
    }

        .halspan-logo img {
            width: 200px;
            max-width: 100%;
            height: auto;
            transition-duration: 0.3s;
        }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .halspan-logo {
        padding: 10px 0 10px 10px;
    }

        .halspan-logo img {
            width: 200px;
            max-width: 100%;
            height: auto;
            transition-duration: 0.3s;
        }

    .nav-contact-button {
        display: block;
        line-height: 1;
        background-color: #E4282B;
        font-size: 15px;
        padding: 12px 24px;
        border-radius: 3px;
        font-family: Helvetica, Arial, sans-serif;
        border-radius: 3px;
        color: #fff !important;
        fill: #fff;
        text-align: center;
        transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
        border-radius: 50px;
        font-weight: bold;
        text-decoration: none;
        margin: 30px 0;
    }

        .nav-contact-button:hover {
            background-color: #000000;
            text-decoration: none;
            color: #ffffff;
        }

        img.img-fluid.float-right.position-absolute {
            right: 12px;
        }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .halspan-logo {
        padding: 10px 0;
    }

        .halspan-logo img {
            width: auto;
            max-width: 100%;
            height: 60px;
            margin-top: -7px;
        }

    .nav-menu {
        display: none;
    }
}


/* Purely Menu responsiveness !!! */
@media (min-width: 1368px) {
    ul.nav-menu {
        background: #ffffff;
        list-style: none;
        margin: 0 10px 0 0;
        padding: 0;
        font-family: Arial, Helvetica, sans-serif, Arial, sans-serif;
        font-size: 16px;
        display: inline-block;
    }

        ul.nav-menu li {
            color: #525252;
            background: #ffffff;
            font-weight: bold;
            display: block;
            float: left;
            padding: 0;
            position: relative;
            text-decoration: none;
            transition-duration: 0.5s;
        }

            ul.nav-menu li a {
                color: #525252;
                text-decoration: none !important;
                padding: 38px 15px;
                display: block;
            }

            ul.nav-menu li:hover a {
                color: #e6272b;
            }

            ul.nav-menu li:hover ul li a,
            ul.nav-menu li:focus ul li a {
                color: #ffffff;
            }

            ul.nav-menu li:hover,
            ul.nav-menu li:focus {
                color: #e6272b;
                cursor: pointer;
            }

            ul.nav-menu li ul {
                background: #525252;
                visibility: hidden;
                opacity: 0;
                min-width: 10em;
                max-width: 1000px;
                position: absolute;
                transition: all 0.5s ease;
                margin-top: 0px;
                left: 0;
                display: none;
                padding-left: 0;
            }

                ul.nav-menu li:hover > ul,
                ul.nav-menu li ul:hover,
                ul.nav-menu li ul:focus {
                    visibility: visible;
                    opacity: 1;
                    display: block
                }

                ul.nav-menu li ul li {
                    clear: both;
                    width: 100%;
                    background: #525252;
                    color: #ffffff;
                    padding: 0;
                    font-size: 13px;
                    line-height: 20px;
                    padding-left: 8px;
                    white-space: nowrap;
                }

                    ul.nav-menu li ul li:hover {
                        background: #272627;
                    }

                    ul.nav-menu li ul li a {
                        padding: 13px 20px;
                        display: block;
                    }

                    ul.nav-menu li ul li a,
                    ul.nav-menu li ul li:hover a {
                        color: #ffffff;
                    }

    .nav-menu-mobile {
        display: none;
    }

    .nav-menu-mobile-toggle,
    .nav-menu-mobile-toggle-expanded {
        display: none;
    }
}

@media (max-width: 1367px) {
    .halspan-navbar .navbar .col-md-3 {
        width:50%;
    }
    .halspan-navbar .navbar .col-md-9 {
        width: 50%;
    }
    
    .nav-contact-button {
        display: none;
    }

    ul.nav-menu {
        display: none;
    }

    .nav-menu-mobile-toggle, 
    .nav-menu-mobile-toggle-expanded {
        display: block;
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 30px 0 30px 30px;
    }

    .nav-menu-mobile-toggle-expanded {
        display: none;
    }

    .nav-menu-mobile-toggle i,
    .nav-menu-mobile-toggle-expanded i {
        width: 40px;
        height: 40px;
        font-size: 26px;
        line-height: 40px;
        color: #525252;
        display: inline-block;
        cursor: pointer;
        vertical-align: middle;
        text-align: center;
        transition: all .2s;
        -o-transition: all .2s;
        transition: all .2s;
    }

        .nav-menu-mobile-toggle i:hover,
        .nav-menu-mobile-toggle-expanded i:hover {
            color: #E4282B;
        }

    .nav-menu-mobile {
        /*position: absolute;*/
        width: 100%;
        top: 100px;
        left: 0;
        background: #525252;
        padding: 20px;
        display: none;
        z-index: 1000;
        font-family: Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-weight: 400;
    }

        .nav-menu-mobile * {
            font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-smoothing: antialiased;
        }

        .nav-menu-mobile,
        .nav-menu-mobile ul,
        .nav-menu-mobile ul li,
        .nav-menu-mobile ul li a {
            margin: 0;
            padding: 0;
            border: 0;
            list-style: none;
            line-height: 1;
            position: relative;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }

    .nav-menu-mobile {
        position: fixed;
    }

        .nav-menu-mobile ul, .nav-menu-mobile ul li, .nav-menu-mobile ul li a {
            display: block;
        }

            .nav-menu-mobile ul ul {
                display: none;
            }

        .nav-menu-mobile > ul > li > a {
            padding: 13px 20px;
            cursor: pointer;
            z-index: 2;
            font-size: 14px;
            font-weight: bold;
            text-decoration: none;
            color: #ffffff;
        }

            /*expanded*/
            .nav-menu-mobile > ul > li > a:hover,
            .nav-menu-mobile > ul > li.active > a,
            .nav-menu-mobile > ul > li.open > a {
                color: #ffffff;
                background: #272727;
            }

                .nav-menu-mobile > ul > li > a:hover > span::after,
                .nav-menu-mobile > ul > li.active > a > span::after,
                .nav-menu-mobile > ul > li.open > a > span::after {
                    border: none;
                }

        .nav-menu-mobile ul ul li a {
            cursor: pointer;
            border: none;
            padding: 13px 35px;
            z-index: 1;
            text-decoration: none;
            font-size: 12px;
            font-weight: bold;
            color: #ffffff;
            background: #525252;
        }

        .nav-menu-mobile ul ul li:hover > a,
        .nav-menu-mobile ul ul li.open > a,
        .nav-menu-mobile ul ul li.active > a {
            background: #272727;
            color: #ffffff;
        }

        .nav-menu-mobile ul ul li:first-child > a {
            box-shadow: none;
        }

        .nav-menu-mobile ul ul ul li:first-child > a {
            box-shadow: none;
        }

        .nav-menu-mobile ul ul ul li a {
            padding-left: 30px;
        }

        .nav-menu-mobile > ul > li > ul > li:last-child > a,
        .nav-menu-mobile > ul > li > ul > li.last > a {
            border-bottom: 0;
        }

        .nav-menu-mobile > ul > li > ul > li.open:last-child > a,
        .nav-menu-mobile > ul > li > ul > li.last.open > a {
            border-bottom: none;
        }

        .nav-menu-mobile > ul > li > ul > li.open:last-child > ul > li:last-child > a {
            border-bottom: 0;
        }

        .nav-menu-mobile ul ul li.has-sub > a::after {
            display: block;
            position: absolute;
            content: "";
            width: 5px;
            height: 5px;
            right: 20px;
            z-index: 10;
            top: 11.5px;
            border-top: none;
            border-left: none;
            transform: rotate(-135deg);
            -moz-transform: rotate(-135deg);
            -ms-transform: rotate(-135deg);
            -o-transform: rotate(-135deg);
            transform: rotate(-135deg);
        }

        .nav-menu-mobile ul ul li.active > a::after,
        .nav-menu-mobile ul ul li.open > a::after,
        .nav-menu-mobile ul ul li > a:hover::after {
            border-color: #ffffff;
        }

        .nav-menu-mobile ul li.contactus {
            background-color: #E6272B;
        }

    .holder::after {
        top: 17px;
        border: none;
    }

    .holder::before {
        top: 18px;
        border: none;
    }
}

