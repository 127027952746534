@import "@styles/variables";

.halspan-loader-content {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    > img {
        width: 300px;
    }

    .spinner-border {
        color: $primary-color;
    }
}